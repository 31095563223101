import service from '@/utils/request'; // @Tags ElmOutLinks
// @Summary 创建ElmOutLinks
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.ElmOutLinks true "创建ElmOutLinks"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /elmOutLinks/createElmOutLinks [post]

export var createElmOutLinks = function createElmOutLinks(data) {
  return service({
    url: "/elmOutLinks/createElmOutLinks",
    method: 'post',
    data: data
  });
}; // @Tags ElmOutLinks
// @Summary 删除ElmOutLinks
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.ElmOutLinks true "删除ElmOutLinks"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /elmOutLinks/deleteElmOutLinks [delete]

export var deleteElmOutLinks = function deleteElmOutLinks(data) {
  return service({
    url: "/elmOutLinks/deleteElmOutLinks",
    method: 'delete',
    data: data
  });
};
export var distribute = function distribute(data) {
  return service({
    url: "/elmOutLinks/distribute",
    method: 'put',
    data: data
  });
};
export var upload = function upload(data) {
  return service({
    url: "/elmOutLinks/uploadorder",
    method: 'post',
    data: data
  });
}; // @Tags ElmOutLinks
// @Summary 删除ElmOutLinks
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除ElmOutLinks"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /elmOutLinks/deleteElmOutLinks [delete]

export var deleteElmOutLinksByIds = function deleteElmOutLinksByIds(data) {
  return service({
    url: "/elmOutLinks/deleteElmOutLinksByIds",
    method: 'delete',
    data: data
  });
}; // @Tags ElmOutLinks
// @Summary 更新ElmOutLinks
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.ElmOutLinks true "更新ElmOutLinks"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /elmOutLinks/updateElmOutLinks [put]

export var updateElmOutLinks = function updateElmOutLinks(data) {
  return service({
    url: "/elmOutLinks/updateElmOutLinks",
    method: 'put',
    data: data
  });
}; // @Tags ElmOutLinks
// @Summary 用id查询ElmOutLinks
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.ElmOutLinks true "用id查询ElmOutLinks"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /elmOutLinks/findElmOutLinks [get]

export var findElmOutLinks = function findElmOutLinks(params) {
  return service({
    url: "/elmOutLinks/findElmOutLinks",
    method: 'get',
    params: params
  });
}; // @Tags ElmOutLinks
// @Summary 分页获取ElmOutLinks列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取ElmOutLinks列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /elmOutLinks/getElmOutLinksList [get]

export var getElmOutLinksList = function getElmOutLinksList(params) {
  return service({
    url: "/elmOutLinks/getElmOutLinksList",
    method: 'get',
    params: params
  });
};